<template>
  <custom-form :id="form.id" :class="['educationregistration', 'loader-wrapper', { 'is-sending': isSending }]" @customform:submit="onSubmit">
    <c-leaf>
      <header class="educationregistration-header">
        <slot name="title" />
      </header>

      <c-article-data>
        <template #title>
          <h2>{{ $T('user.Title') }}</h2>
        </template>

        <entity-data :isBusy="userIs.loading" entityKey="users" :entityId="user.id || ''" />

        <div class="entity-action" v-if="userEditHref && userEditHref !== '#'">
          <b-link :href="userEditHref"><b-icon icon="edit" /> {{ $T('user.EditButton') }}</b-link>
        </div>
      </c-article-data>

      <c-article-data>
        <template #title>
          <h2>{{ $T('userprofession.Title') }}</h2>
        </template>

        <entity-data :isBusy="userProfessionIs.loading" entityKey="userprofessions" :entityId="currentUserProfession.id || ''" />

        <div class="entity-action" v-if="userProfessionEditHref && userProfessionEditHref !== '#'">
          <b-link :href="userProfessionEditHref"><b-icon icon="edit" /> {{ $T('userprofession.EditButton') }}</b-link>
        </div>
      </c-article-data>

      <c-article-data>
        <template #title>
          <h2>{{ $T('correspondenceaddress.Title') }}</h2>
        </template>

        <p class="text-md text-bold">{{ correspondenceAddress.text }}</p>

        <div class="entity-action" v-if="userAddressEditHref && userAddressEditHref !== '#'">
          <b-link :href="userAddressEditHref"><b-icon icon="edit" /> {{ $T('invoiceAddress.EditButton') }}</b-link>
        </div>
      </c-article-data>

      <c-article-data v-if="isInvoiceAddressControlVisible">
        <template #title>
          <h2>{{ $T('invoiceaddress.Title') }}</h2>
        </template>
        <template v-if="invoiceAddresses.useDefault !== null">
          <template v-if="invoiceAddresses.noneAvailable">
            <div class="d-block invalid-feedback">{{ $T('invoiceAddress.NoAddress') }}</div>
          </template>

          <template v-else>
            <div v-if="invoiceAddresses.useDefault" class="address-item">
              <p class="text-md text-bold">{{ invoiceAddressesControlOptions[0].text }}</p>
            </div>

            <control-radio v-show="!invoiceAddresses.useDefault" class="invoiceaddresses-control" v-model="form.invoiceAddress.value" v-bind="form.invoiceAddress" stacked />

            <div class="mt-3" v-if="invoiceAddresses.severalAvailable">
              <b-link @click="toggleInvoiceAddresses">{{ invoiceAddresses.useDefault ? $T('invoiceAddress.UseOtherInvoiceAddressButton') : $T('invoiceAddress.UseDefaultInvoiceAddressButton') }}</b-link>
            </div>
          </template>
        </template>

        <div class="entity-action" v-if="userAddressEditHref && userAddressEditHref !== '#'">
          <b-link :href="userAddressEditHref"><b-icon icon="edit" /> {{ $T('invoiceAddress.EditButton') }}</b-link>
        </div>
      </c-article-data>
      <slot />
    </c-leaf>

    <loader v-if="isSending">
      {{ $T('submitLoader.Text') }}
    </loader>
  </custom-form>
</template>

<script>
import { ADDRESS_TYPES } from '@/assets/js/config/server'

import Loader from '@/components/Loader'
import EntityData from '@/components/authenticated/Entity/Data'

import { valueFormatter } from '@/assets/js/helper/entity'

export default {
  name: 'EducationRegistration',
  components: {
    Loader,
    EntityData
  },
  props: {
    id: {
      type: String,
      required: true
    },
    controlInvoiceaddress: {
      type: Object,
      required: true
    },
    userEditHref: {
      type: String,
      default: null
    },
    userProfessionEditHref: {
      type: String,
      default: null
    },
    userAddressEditHref: {
      type: String,
      default: null
    },
    isInvoiceAddressControlVisible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isSending: false,
      invoiceAddresses: {
        loaded: {
          userAddresses: false,
          locationAddresses: false
        },
        noneAvailable: true,
        severalAvailable: false,
        useDefault: null
      },
      form: {
        id: this.id,
        invoiceAddress: Object.assign({
          id: `${this.id}_InvoiceAddress`,
          value: null,
          options: []
        }, this.controlInvoiceaddress)
      }
    }
  },
  computed: {
    userIs () {
      return this.$store.getters['users/is']
    },
    user () {
      return this.$store.getters['users/getSelfUnwrapped']
    },
    userDefinition () {
      return this.$store.getters['users/definition']
    },
    userProfessionIs () {
      return this.$store.getters['userprofessions/getCurrent']
    },
    currentUserProfession () {
      return this.$store.getters['userprofessions/getCurrentUnwrapped']
    },
    userAddresses () {
      return this.$store.getters['useraddresses/getUnwrapped']
    },
    defaultLocation () {
      return this.$store.getters['locations/getEntityUnwrapped'](this.$store.getters['profile/getActiveLocationId'])
    },
    invoiceAddressesControlOptions () {
      const userDefaultAddress = this.userAddresses.filter(a => a.type === ADDRESS_TYPES.default)
      const userInvoiceAddress = this.userAddresses.filter(a => a.type === ADDRESS_TYPES.invoice)
      const locationDefaultAddress = this.$store.getters['locationaddresses/getUnwrapped'].filter(a => a.type === undefined || a.type === ADDRESS_TYPES.default)
      const user = this.user
      const location = this.defaultLocation

      const userSalutation = valueFormatter(this.userDefinition, 'salutation', user.salutation).value
      const userAcademicTitle = user.academicTitle && user.academicTitle !== 'None' ? valueFormatter(this.userDefinition, 'academicTitle', user.academicTitle).value : ''
      const userNamePrefix = userAcademicTitle.length > 0 ? `${userSalutation} ${userAcademicTitle}` : userSalutation

      return []
        .concat(
          userDefaultAddress,
          userInvoiceAddress,
          locationDefaultAddress
        )
        .map(a => ({
          value: a.id,
          text: [(a.locationId ? location.name : false), (a.userId ? a.company : false), `${userNamePrefix} ${user.firstname} ${user.lastname}`, a.street, `${a.zip} ${a.city}`].filter(i => i).join(', ')
        }))
    },
    correspondenceAddress () {
      const userDefaultAddresses = this.userAddresses.filter(a => a.type === ADDRESS_TYPES.default)
      const userDefaultAddress = userDefaultAddresses && userDefaultAddresses.length > 0 ? userDefaultAddresses[0] : {}
      const user = this.user

      const userSalutation = valueFormatter(this.userDefinition, 'salutation', user.salutation).value
      const userAcademicTitle = user.academicTitle && user.academicTitle !== 'None' ? valueFormatter(this.userDefinition, 'academicTitle', user.academicTitle).value : ''
      const userNamePrefix = userAcademicTitle.length > 0 ? `${userSalutation} ${userAcademicTitle}` : userSalutation

      return {
        value: userDefaultAddress.id,
        text: [userDefaultAddress.company, `${userNamePrefix} ${user.firstname} ${user.lastname}`, userDefaultAddress.street, `${userDefaultAddress.zip} ${userDefaultAddress.city}`].filter(i => i).join(', ')
      }
    }
  },
  methods: {
    getInitialData () {
      this.$store.dispatch('users/getSelf')
      this.$store.dispatch('userprofessions/getCurrent', { by: { name: 'user', id: this.$store.getters['profile/getUserId'] } })

      this.$store.dispatch('useraddresses/getAll', { by: { name: 'user', id: this.$store.getters['profile/getUserId'] } })
        .then(() => {
          this.setInitialInvoiceAddresses('userAddresses')
        })

      if (this.$store.getters['profile/getActiveLocationId'] !== null) {
        this.$store.dispatch('locations/getEntity', this.$store.getters['profile/getActiveLocationId'])
        this.$store.dispatch('locationaddresses/getAll', { by: { name: 'location', id: this.$store.getters['profile/getActiveLocationId'] } })
          .then(() => {
            this.setInitialInvoiceAddresses('locationAddresses')
          })
      } else {
        this.setInitialInvoiceAddresses('locationAddresses')
      }
    },
    setInitialInvoiceAddresses (loadedKey = '') {
      this.invoiceAddresses.loaded[loadedKey] = true

      if (Object.values(this.invoiceAddresses.loaded).every(l => l === true)) {
        this.invoiceAddresses.useDefault = true
        this.form.invoiceAddress.value = this.invoiceAddressesControlOptions[0].value
        this.form.invoiceAddress.options = this.invoiceAddressesControlOptions

        this.invoiceAddresses.noneAvailable = this.form.invoiceAddress.options.length <= 0
        this.invoiceAddresses.severalAvailable = this.form.invoiceAddress.options.length > 1
      }
    },
    toggleInvoiceAddresses () {
      this.invoiceAddresses.useDefault = !this.invoiceAddresses.useDefault
      this.form.invoiceAddress.value = this.invoiceAddressesControlOptions[0].value
    },
    onSubmit () {
      this.$el.querySelectorAll('[type="submit"]')
        .forEach(el => {
          el.setAttribute('disabled', true)
        })

      this.isSending = true
    }
  },
  created () {
    this.getInitialData()
  }
}
</script>

<style lang="scss">
$educationregistration-sending-bg: rgba($white, 0.8) !default;

.educationregistration {
  .articledata {
    .articledata-content {
      .invoiceaddresses-control {
        font-size: $font-size-base;

        .custom-control {
          >input {
            &:checked {
              +label {
                font-weight: $font-weight-bold;
              }
            }
          }
        }
      }
    }

    .entity-action {
      margin-top: $spacer;
      text-align: right;
    }
  }

  &.is-sending {
    position: relative;

    &:after {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $educationregistration-sending-bg;
      z-index: 1900;
    }
  }
}
</style>
